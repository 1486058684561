import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.section`
  padding: 0.48rem 0 0.56rem;
  text-align: center;
  .titles {
    > h1 {
      color: #969696;
      font-size: 0.16rem;
      text-transform: uppercase;
    }
    > h2 {
      color: #636363;
      font-size: 0.3rem;
      font-weight: bold;
    }
    .line {
      width: 0.9rem;
      height: 2px;
      background-color: #ff7c34;
      margin: 0.2rem auto 0.36rem;
    }
  }
  .map {
    width: 100%;
    height: 3.34rem;
    padding: 0 0.7rem;
  }
  .desc {
    padding: 0 0.7rem;
    text-align: left;
    > p {
      font-size: 0.24rem;
      line-height: 1.6;
      color: #636363;

      span {
        color: #636363;
        display: inline-block;
      }
      .tit {
        font-weight: bold;
      }
      &:not(:last-child) {
        margin-bottom: 0.7rem;
      }
    }
  }
  .logos {
    /* width: 44.5rem; */
    display: flex;
    flex-wrap: wrap;
    align-self: flex-start;
    margin-top: 0.31rem;
    padding: 0 0.68rem;
    > img {
      width: 2.85rem;
      height: 1.37rem;
      margin-bottom: 0.52rem;
      &:nth-child(odd) {
        margin-right: 0.4rem;
      }
      &:last-child {
        margin: 0 auto 0.4rem;
      }
    }
  }
`;
export default function Section({ title, subTitle, logos = null, desc, LocImg = null }) {
  return (
    <Wrapper>
      <div data-aos="fade-down" className="titles">
        <h2>{subTitle}</h2>
        <h1>{title}</h1>
        <p className="line"></p>
      </div>
      {LocImg && <img src={LocImg} alt="公司地址配图" className="map" />}
      {desc && (
        <div data-aos="fade-up" className="desc">
          {desc.map((ps, idx) => {
            return ps.title ? (
              <p key={ps.title}>
                <span className="tit">{ps.title}</span>
                {ps.text}
              </p>
            ) : (
              <p key={idx}>
                {ps.map(p => (
                  <span key={p}>{p}</span>
                ))}
              </p>
            );
          })}
        </div>
      )}
      {logos && (
        <div data-aos="fade-up" className="logos">
          {logos.map((logo, idx) => (
            <img key={idx} src={logo} alt="coop logo" />
          ))}
        </div>
      )}
    </Wrapper>
  );
}
